var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"dtc-main-section mt-2 mr-2"},[_c('ViewStudentInfo'),_c('section',{staticClass:"search-block"},[_c('h5',{staticClass:"big-title"},[_c('span',{staticClass:"mr-3"},[_vm._v("編輯待遇補助經費申請明細表")]),_vm._v(" "+_vm._s(_vm.editItem.BYear)+"年度 第一學期 培育學校: "+_vm._s(_vm.editItem.school)+" ")]),_c('div',{staticClass:"dtc-search1"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":_vm.openStudentInfo}},[_vm._v("檢視公費生資訊")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.editItem.StatusName == '未預審' || _vm.editItem.StatusName == '未申請' || _vm.editItem.StatusName == '已退回'
            ? false
            : true},on:{"click":_vm.reviseAllData}},[_vm._v("修正")]),_c('b-button',{attrs:{"variant":"warning","disabled":_vm.editItem.StatusName == '未預審' || _vm.editItem.StatusName == '未申請' || _vm.editItem.StatusName == '已退回' || _vm.editItem.StatusName == '預審完成'
            ? false
            : true},on:{"click":_vm.saveAllData}},[_vm._v("儲存")]),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.goBackLastPage}},[_vm._v("返回")]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.openPrintPage}},[_vm._v("列印")])],1)]),_vm._m(0),_vm._l((_vm.items),function(item,i){return _c('main',{key:i,staticClass:"dtc-grid-main main-dtc-body ml-1",staticStyle:{"color":"#dee2e5"},style:(i % 2 == 0
        ? "background-color: #fff;"
        : "background-color: rgb(243 243 243);")},[_c('div',[_vm._v(_vm._s(i + 1))]),_c('div',{attrs:{"title":item.GradeName}},[_vm._v(_vm._s(item.GradeName || ""))]),_c('div',[_vm._v(_vm._s(item.Term))]),(_vm.renewApp)?_c('FormatPriceInput',{key:("Fee_1" + i + _vm.compontKey),class:_vm.editItem.StatusName == '未預審' || _vm.editItem.StatusName == '已退回' || _vm.editItem.StatusName == '未申請'  || _vm.editItem.StatusName == '預審完成'
          ? ''
          : 'avoid-clicks',attrs:{"data":item.Fee_1,"keyIndex":i,"place":"Fee_1","person":item},on:{"update":_vm.saveSingleData}}):_vm._e(),(_vm.renewApp)?_c('FormatPriceInput',{key:("Fee_2" + i + _vm.compontKey),class:_vm.editItem.StatusName == '未預審' || _vm.editItem.StatusName == '已退回' || _vm.editItem.StatusName == '未申請' || _vm.editItem.StatusName == '預審完成'
          ? ''
          : 'avoid-clicks',attrs:{"data":item.Fee_2,"keyIndex":i,"place":"Fee_2","person":item},on:{"update":_vm.saveSingleData}}):_vm._e(),(_vm.renewApp)?_c('FormatPriceInput',{key:("Fee_3" + i + _vm.compontKey),class:_vm.editItem.StatusName == '未預審' || _vm.editItem.StatusName == '已退回' || _vm.editItem.StatusName == '未申請' || _vm.editItem.StatusName == '預審完成'
          ? ''
          : 'avoid-clicks',attrs:{"data":item.Fee_3,"keyIndex":i,"place":"Fee_3","person":item},on:{"update":_vm.saveSingleData}}):_vm._e(),_c('div',{staticClass:"price-content",attrs:{"title":item.Fee_4}},[_vm._v(" "+_vm._s(_vm.$formatPrice(item.Fee_4) || "")+" ")]),_c('div',{staticClass:"price-content",attrs:{"title":item.Fee_5}},[_vm._v(" "+_vm._s(_vm.$formatPrice(item.Fee_5) || "")+" ")]),_c('div',{staticClass:"price-content",attrs:{"title":item.Fee_7}},[_vm._v(" "+_vm._s(_vm.$formatPrice(item.Fee_7) || "")+" ")]),_c('div',{staticClass:"price-content",attrs:{"title":item.Fee_8}},[_vm._v(" "+_vm._s(_vm.$formatPrice(item.Fee_8) || "")+" ")]),_c('div',{staticClass:"price-content",attrs:{"title":item.Fee_9}},[_vm._v(" "+_vm._s(_vm.$formatPrice(item.Fee_9) || "")+" ")]),(_vm.renewApp)?_c('FormatPriceInput',{key:("Fee_11" + i + _vm.compontKey),class:_vm.editItem.StatusName == '未預審' || _vm.editItem.StatusName == '已退回' || _vm.editItem.StatusName == '未申請' || _vm.editItem.StatusName == '預審完成'
          ? ''
          : 'avoid-clicks',attrs:{"data":item.Fee_11,"keyIndex":i,"place":"Fee_11","person":item},on:{"update":_vm.saveSingleData}}):_vm._e(),_c('FormatPriceInput',{key:("ApplyOfPeople" + i + _vm.compontKey),class:_vm.editItem.StatusName == '未預審' || _vm.editItem.StatusName == '已退回' || _vm.editItem.StatusName == '未申請' || _vm.editItem.StatusName == '預審完成'
          ? ''
          : 'avoid-clicks',attrs:{"data":item.ApplyOfPeople,"keyIndex":i,"place":"ApplyOfPeople","person":item,"isTravel":"(1==1)"},on:{"update":_vm.saveSingleData}}),_c('div',{staticClass:"price-content",attrs:{"title":item.Subtotal}},[_vm._v(" "+_vm._s(_vm.$formatPrice(item.Subtotal - item.Fee_13 - item.Fee_12) || "")+" ")])],1)}),_c('footer',{staticClass:"dtc-grid-footer2 main-dtc-body ml-1",staticStyle:{"color":"#212529","background-color":"#fff"}},[_c('div',{staticStyle:{"border-bottom":"1px solid #dee2e5"}},[_vm._v("公費生待遇補助申請金額")]),_c('div',{staticClass:"price-content"},[_vm._v(" "+_vm._s(_vm.$formatPrice(_vm.totalPriceObject.Subtotal - _vm.totalPriceObject.Fee_13 - _vm.totalPriceObject.Fee_12))+" ")])]),_c('footer',{staticClass:"dtc-grid-footer2 main-dtc-body ml-1",staticStyle:{"color":"#212529","background-color":"#fff"}},[_c('div',{staticStyle:{"border-bottom":"1px solid #dee2e5"}},[_vm._v("學校管理費申請金額")]),_c('div',{staticClass:"price-content"},[_vm._v(" "+_vm._s(_vm.$formatPrice(_vm.totalPriceObject.Fee_13))+" ")])]),_c('footer',{staticClass:"dtc-grid-footer2 main-dtc-body ml-1",staticStyle:{"color":"#212529","background-color":"#fff"}},[_c('div',{staticStyle:{"border-bottom":"1px solid #dee2e5"}},[_vm._v("學校設備費申請金額")]),_c('div',{staticClass:"price-content"},[_vm._v(" "+_vm._s(_vm.$formatPrice(_vm.totalPriceObject.Fee_12))+" ")])]),_c('footer',{staticClass:"dtc-grid-footer2 main-dtc-body ml-1",staticStyle:{"color":"#212529","background-color":"#fff"}},[_c('div',{staticStyle:{"border-bottom":"1px solid #dee2e5"}},[_vm._v("合計 ")]),_c('div',{staticClass:"price-content"},[_vm._v(" "+_vm._s(_vm.$formatPrice(_vm.totalPriceObject.Subtotal))+" ")])])],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"dtc-grid-header ml-1 my-dark"},[_c('div',[_vm._v("序")]),_c('div',[_vm._v("年級")]),_c('div',[_vm._v("學期")]),_c('div',{staticClass:"header-column4"},[_c('div',{staticClass:"header-column4-row1"},[_c('div',{staticClass:"header-column4-row1-word"},[_vm._v("註冊費")])]),_c('div',{staticClass:"header-column4-row2"},[_c('div'),_c('div'),_c('div',[_vm._v("其他費用")])]),_c('div',{staticClass:"header-column4-row3"},[_c('div',{staticClass:"header-column4-total",staticStyle:{"border-top":"0px"}},[_vm._v(" 學雜費 ")]),_c('div',{staticClass:"header-column4-total",staticStyle:{"border-top":"0px"}},[_vm._v(" 學生平安保險費 ")]),_c('div',[_vm._v("如語言實習或"),_c('br'),_vm._v("電腦使用費等")])])]),_c('div',{staticClass:"header-column5"},[_c('div',{staticClass:"header-column5-row1"},[_c('div',{staticClass:"header-column5-row1-word"},[_vm._v("生活費")])]),_c('div',{staticClass:"header-column5-row2"},[_c('div',[_vm._v("膳食費")]),_c('div',[_vm._v("零用津貼")]),_c('div',[_vm._v("書籍費")]),_c('div',[_vm._v("制服費")]),_c('div',[_vm._v("旅行參訪費")])]),_c('div',{staticClass:"header-column5-row3"},[_c('div',[_vm._v("3,250元/月*6個月=19,500元/每學期/人")]),_c('div',[_vm._v("3,500元/月*6個月=21,000元/每學期/人")]),_c('div',[_vm._v("4,000元/每學期/人")]),_c('div',[_vm._v("2,500元/每學期/人")]),_c('div',[_vm._v("應屆畢業生3,000元/人")])])]),_c('div',{staticClass:"header-column6"},[_c('div',{staticClass:"header-column6-row1"},[_c('div',{staticClass:"header-column6-row1-word"},[_vm._v("旅宿費")])]),_c('div',{staticClass:"header-column6-row2"},[_c('div',[_vm._v("住宿費")])]),_c('div',{staticClass:"header-column6-row3"},[_c('div',[_vm._v(" 3,000元/月*6個月=18,000元/每學期/人 ")])])]),_c('div',[_vm._v("人數")]),_c('div',[_vm._v("合計")])])}]

export { render, staticRenderFns }